import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { DialogManager } from "../../components/dialogTools/DialogManager";
import { NotificationManager } from "../../components/NotificationManager";
import { GamePageComponent } from "../play/components/GamePageComponent";
import { setGameType } from "../play/globalPlayData";

const logoBlue = "#0055aa";
const logoYellow = "#FFCB05";
const logoFontSize = 110;
const logoFontName = "Assistant";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: "#fff",
      main: "#0055aa",
      // dark: "#000",
    },
    secondary: {
      main: "#FFCB05",
    },
  },
});

setGameType("t");
ReactDOM.render(
  <div style={{ height: "100vh" }}>
    <CssBaseline />
    <NotificationManager />
    <MuiThemeProvider theme={theme}>
      <DialogManager>
        <GamePageComponent />
      </DialogManager>
    </MuiThemeProvider>
  </div>,
  document.getElementById("main")
);
